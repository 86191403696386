
import axiosInstance from "@/plugins/axios";
import i18n from '@/lang/lang'

export default {
    namespaced: true,
    state: {
        config: {
            companyIdSelected: null,
            home: []
        }
    },
    getters: {
        getHome: state => state.config.home,
        getConfigKeyValue: state => state.config
    },
    mutations: {
        setHome(state, home) {
            state.config.home = home;
        },
        setConfigKeyValueSingle(state, { key, value }) {
            state.config[key] = value;

        // console.log('state.config[key]', state.config[key], key, value)
        },
        setConfigKeyValue(state, { key, value }) {
            state.config[key] = { ...state.config[key], ...value };

            // console.log('state.config[key]', state.config[key], key, value)
        }
    },
    actions: {
        async loadHomeConfig({
            commit,
            state,
            dispatch
        }, forceLoad = false) {
            try {
                if (!forceLoad && state.config?.home?.length) {
                    return state.config.home;
                }
                const configHome = await axiosInstance
                    .get('configHomeShow')

                const data = configHome.data.data;

                commit('setHome', data)

                return data;
            } catch (error) {
                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        },
        async setConfigKeyValue({ commit, dispatch }, { key, value }) {
            commit('setConfigKeyValueSingle', { key, value });
        },
        async loadConfigGeneral({ commit, state }) {
            try {
                // if (state.getConfigKeyValue.competition.end instanceof Date) return state.getConfigKeyValue;

                const configs = await axiosInstance
                    .get('getConfigGeneral'); // ?filter[name]=competition

                const configGenerals = configs?.data.config_generals;
                if (configGenerals.length) {
                    configGenerals.forEach(cConf => {
                        const name = cConf.name;
                        let options = cConf.options;
                        if (options) {
                            options = JSON.parse(options);
                            const value = options;

                            commit('setConfigKeyValue', { key: name, value });
                        }
                    });
                }

                // console.log(' ********* state.config ******* ', state.config);
                return state.config;
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        }
    }
}
