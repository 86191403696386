<template>
    <v-icon
        v-if="mdiIcon"
        :color="color || getColorPrimary || 'default_icons_color'"
        :icon="icon"
        :class="classes"
        :x-small="xSmall"
        :small="small"
        :large="large"
        :x-large="xLarge"
        :dark="dark"
        :light="light"
        :disabled="disabled"
        @click="$emit('click',$event)"
        @click.prevent="$emit('click.prevent',$event)"
        @click.stop="$emit('click.stop',$event)"
    >
        {{ mdiIcon }}
    </v-icon>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    props: {
        dark: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: undefined
        },
        text: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        },
        classes: {
            type: String,
            default: undefined
        },
        rounded: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: false
        },
        icon: {
            type: Boolean,
            default: false
        },
        mdiIcon: {
            type: String,
            default: undefined
        },
        link: {
            type: String,
            default: ''
        },
        small: {
            type: Boolean,
            default: false
        },
        xSmall: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        xLarge: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['getColorPrimary'])
    }
}
</script>
