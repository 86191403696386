/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "./plugins";

import octaviaKit from "@/plugins/octavia.kit";

import '@/components'

import VueApexCharts from 'vue-apexcharts';

import i18n from './lang/lang'

import helpers from '@/helpers'
import './registerServiceWorker'
// mock
// import "./fake-db/index.js";

Vue.component('apexchart', VueApexCharts);

/* import Echo from "laravel-echo"

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '8096cd26e10fe6effd7e',
    forceTLS: false,
    disableStats: true,
    cluster: 'eu',
    authEndpoint: 'https://backgameboard.3weeksconsulting.com/broadcasting/auth'
    // authEndpoint: 'http://127.0.0.1:8000/broadcasting/auth'
}); */

Vue.use(helpers);

Vue.use(octaviaKit);
Vue.config.productionTip = false;
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

// Para marcar las palabras que coinciden con la busqueda (add un color a la coincidencia de un texto en otro)
Vue.filter('highlight', function (value, query) {
    if (query?.length && value?.length) { return value.replace(new RegExp(query, "ig"), '<span class=\'primary\'>' + query + '</span>') } else return value;
});

new Vue({
    store,
    router,
    vuetify,
    i18n,
    created() {
        this.initialize();
    },
    methods: {
        initialize: function () {
            "use strict";

            try {
                const lang = this.$store.getters['languages/getLang'];
                if (lang) {
                    this.$i18n.locale = lang;
                }
            } catch (e) {}
        }
    },
    render: h => h(App)
}).$mount("#app");
