import axiosInstance from '@/plugins/axios'
import axios from '@/plugins/axios'
import authData from "@/store/modules/authData";

export default {
    namespaced: true,
    state: {
        /* posts: [],
        postsHashId: {}, // postsHashId[id] === indexOfPost */
        persistentInfo: localStorage.getItem('persistentInfo') != null
            ? JSON.parse(localStorage.getItem('persistentInfo'))
            : {}

    },
    getters: {
        /* getPosts: state => state.posts,
        getPostsOrdered: (state, getters, rootState, rootGetters) => {
            const lang = rootGetters["languages/getLang"];

            return state.posts
                .sort((a, b) => parseInt(a.attributes.order) > parseInt(b.attributes.order) ? 1 : -1)
                .filter(post => {
                    if (post.attributes.lang === lang) {
                        if (post.attributes.url !== "wasNominated") return true;
                        return rootGetters["configGeneral/wasNominated"];
                    }
                });
        },
        getCountPostsVisibles: (state, getters, rootState, rootGetters) => {
            const max = getters.getPostsOrdered.length;
            const indexShowPost = rootGetters.getIndexShowPost;
            let countVisibles = indexShowPost >= max ? max : indexShowPost + 1;

            if (countVisibles > 0 && countVisibles >= max - 2) {
                if (rootGetters["configGeneral/getConfigKeyValue"].competition.isOpen === false) {
                    countVisibles = max;
                } else {
                    countVisibles = max - 1;
                    // countVisibles = rootGetters["configGeneral/getConfigKeyValue"].votation.isOpen ? max - 1 : max - 2;
                }
            }

            return countVisibles;
        } */

    },
    mutations: {
        /* setPosts(state, posts) {
            state.posts = posts
        }, */
        setPersistentInfo(state, { key, value }) {
            state.persistentInfo[key] = value;
            localStorage.setItem('persistentInfo', JSON.stringify(state.persistentInfo));
        }
    },
    actions: {
        /* async loadPosts({
            commit,
            state
        }, { company = '' }) {
            try {
                if (state.posts.length) {
                    const companyPrevious = state.posts[0].attributes['company-id'];

                    if (companyPrevious == company) {
                        return state.posts;
                    }
                }

                const params = {
                    sort: 'order'
                };

                if (company)params['filter[company]'] = company;
                else params['filter[my_company]'] = '1';

                const posts = await axiosInstance
                    .get('posts', {
                        params
                    })

                commit('setPosts', posts.data.data)

                return state.posts
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }, */
        getAndUpdatePersistentInfo({ state, getters, commit }, { key, value }) {
            const previousValue = state.persistentInfo[key];

            if (previousValue !== value) { commit('setPersistentInfo', { key, value }); }

            return previousValue;
        }
    }
}
