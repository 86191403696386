// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import authenticate from "@/auth/authenticate";
import admin from "@/auth/admin";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return {
            x: 0,
            y: 0
        };
    },
    routes: [
        {
            path: "/",
            component: () => import(/* webpackChunkName: "chunk-r-IndexHome" */ "@/views/app/Index"),
            beforeEnter: authenticate,
            redirect: "/app/pages/Home",

            children: [
                // {
                //   path: "layout/:layout",
                //   component: () => import("@/views/app/Index")
                // },

                {
                    path: "/app/pages",
                    component: () => import(/* webpackChunkName: "chunk-r-IndexHome" */ /* webpackPrefetch: 9999 */ "@/views/app/pages/Pages"),
                    children: [
                        {
                            path: "home",
                            component: () => import(/* webpackChunkName: "chunk-r-IndexHome" */ /* webpackPrefetch: 9999 */ "@/views/app/pages/Home")
                        },
                        /* {
                            path: "post/:url",
                            component: () => import( "@/views/app/pages/PostView")
                        }, */
                        {
                            path: "profile",
                            component: () => import(/* webpackChunkName: "chunk-r-Profile" */ "@/views/app/pages/Profile")
                        },
                        {
                            path: "termsAccepted",
                            component: () => import(/* webpackChunkName: "chunk-r-TermsAccepted" */ "@/views/app/pages/TermsAccepted")
                        },
                        {
                            path: "legalBasesAccepted",
                            component: () => import(/* webpackChunkName: "chunk-r-LegalBasesAccepted" */ "@/views/app/pages/LegalBasesAccepted")
                        },
                        /* {
                            path: "statistics",
                            component: () => import("@/views/app/pages/StatisticsDetails")
                        }, */
                        {
                            path: "documents",
                            component: () => import(/* webpackChunkName: "chunk-r-IndexHome" */ /* webpackPrefetch: 9999 */ "@/views/app/pages/Pages"),
                            children: [
                                {
                                    path: "/",
                                    component: () => import(/* webpackChunkName: "chunk-r-IndexHome" */ /* webpackPrefetch: 9999 */ "@/modules/documents/views/pages/DocumentsArea")
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            path: "/app/admin",
            component: () => import(/* webpackChunkName: "chunk-r-Admin" */ "@/views/app/IndexAdmin"),
            beforeEnter: admin,

            children: [
                {
                    path: "/",
                    component: () => import(/* webpackChunkName: "chunk-r-AdminPages" */ "@/views/app/pages/admin/Pages"),
                    redirect: "/app/admin/dashboard",
                    children: [
                        {
                            path: "dashboard",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminDashBoard" */ "@/views/app/pages/admin/DashBoard")
                        },
                        {
                            path: "users",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminUsers" */ "@/views/app/pages/admin/Users")
                        },
                        {
                            path: "roles",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminRoles" */ "@/views/app/pages/admin/Roles")
                        },
                        {
                            path: "styles",
                            name: "LookAndFeelManager",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminLookAndFeelManager" */ "@/views/app/pages/admin/LookAndFeelManager")
                        },
                        /* {
                            path: "posts/create/:id",
                            name: "PostsCreate",
                            component: () => import("@/views/app/pages/admin/PostsCreate")
                        }, */
                        {
                            path: "posts",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminPosts" */ "@/views/app/pages/admin/Posts")
                        },
                        {
                            path: "terms/create",
                            name: "TermsCreate",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminTermsCreate" */
                                /* webpackExports: ["chunk-r-Admin", "chunk-r-AdminTerms"] */ "@/views/app/pages/admin/TermsCreate")
                        },
                        {
                            path: "terms",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminTerms" */ "@/views/app/pages/admin/Terms")
                        },
                        {
                            path: "configs",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminConfigs" */ "@/views/app/pages/admin/Configs")
                        },
                        {
                            path: "home",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminHomeConfig" */ "@/views/app/pages/admin/HomeConfig")
                        },
                        {
                            path: "reports",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminPages" */ "@/views/app/pages/admin/Pages"),
                            children: [
                                {
                                    path: "access-habits",
                                    component: () => import(/* webpackChunkName: "chunk-r-AdminReportAccessHabits" */ "@/views/app/pages/admin/reports/AccessHabits")
                                },
                                {
                                    path: "visualizations",
                                    component: () => import(/* webpackChunkName: "chunk-r-AdminReportVisualizations" */ "@/views/app/pages/admin/reports/Visualizations")
                                }
                            ]
                        },
                        {
                            path: "documents",
                            component: () => import(/* webpackChunkName: "chunk-r-AdminPages" */ "@/views/app/pages/admin/Pages"),
                            children: [
                                {
                                    path: "list",
                                    component: () => import(/* webpackChunkName: "chunk-r-AdminDocumentList" */ "@/modules/documents/views/pages/admin/DocumentList")
                                },
                                {
                                    path: "create/:id",
                                    name: "DocumentCreate",
                                    component: () => import(/* webpackChunkName: "chunk-r-AdminDocumentCreate" */ "@/modules/documents/views/pages/admin/DocumentCreate")
                                },
                                {
                                    path: "tags",
                                    component: () => import(/* webpackChunkName: "chunk-r-AdminTagList" */ "@/modules/documents/views/pages/admin/TagList")
                                },
                                {
                                    path: "types",
                                    component: () => import(/* webpackChunkName: "chunk-r-AdminDocumentTypeList" */ "@/modules/documents/views/pages/admin/DocumentTypeList")
                                }
                            ]
                        }

                    ]
                }
            ]
        },
        {
            path: "/app/sessions",
            component: () => import(/* webpackChunkName: "chunk-r-Sessions" */ "@/views/app/sessions/Sessions"),

            children: [
                /* {
            path: "sign-in",
            component: () => import("@/views/app/sessions/SignIn")
        }, */
                {
                    path: "error",
                    component: () => import(/* webpackChunkName: "chunk-r-SessionError" */ "@/views/app/sessions/Error")
                },
                {
                    path: "forgot",
                    component: () => import(/* webpackChunkName: "chunk-r-SessionForgot" */ "@/views/app/sessions/Forgot")
                },
                {
                    path: "pass-reset",
                    component: () => import(/* webpackChunkName: "chunk-r-SessionNewPassword" */ "@/views/app/sessions/NewPassword")
                },
                /* {
            path: "lockscreen",
            component: () => import("@/views/app/sessions/Lockscreen")
        }, */
                {
                    path: "sign-in-two",
                    name: "login",
                    component: () => import(/* webpackChunkName: "chunk-r-SessionSignInUsernameCode" */ "@/views/app/sessions/SignInUsernameCode")
                },
                /* {
            path: "sign-in-three",
            component: () => import("@/views/app/sessions/SignInThree")
        },
        {
            path: "sign-in-four",
            component: () => import("@/views/app/sessions/SignInFour")
        },
        {
            path: "sign-in-five",
            component: () => import("@/views/app/sessions/SignInFive")
        },
        {
            path: "sign-up",
            component: () => import("@/views/app/sessions/SignUp")
        }, */
                {
                    path: "sign-up-2",
                    name: "signup",
                    component: () => import(/* webpackChunkName: "chunk-r-SessionSignUpTwo" */ "@/views/app/sessions/SignUpTwo")
                }/*,
                {
                    path: "sign-up-5",
                    component: () => import("@/views/app/sessions/SignUpFive")
                } */
            ]
        },
        {
            // will match everything (use for not found route error:404)
            path: '*',
            redirect: "/app/pages/Home"
        }
    ]
});

router.beforeEach((to, from, next) => {
    // If this isn't an initial page load.
    if (to.path) {
    // Start the route progress bar.
        store.dispatch("changeThemeLoadingState", true);
    }
    next();
});

router.afterEach(() => {
    // Remove initial loading
    // const gullPreLoading = document.getElementById("loading_wrap");
    // if (gullPreLoading) {
    //   gullPreLoading.style.display = "none";
    // }
    // Complete the animation of the route progress bar.
    setTimeout(() => store.dispatch("changeThemeLoadingState", false), 500);
    // NProgress.done();
    // if (isMobile) {
    // if (window.innerWidth <= 1200) {
    //   // console.log("mobile");
    //   store.dispatch("changeSidebarProperties");
    //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
    //     store.dispatch("changeSecondarySidebarProperties");
    //   }
    //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
    //     store.dispatch("changeCompactSidebarProperties");
    //   }
    // } else {
    //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
    //     store.dispatch("changeSecondarySidebarProperties");
    //   }
    //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
    // }
});

export default router;
