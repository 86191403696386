import { themeConfig, themePreset } from "@/themeConfig";
import Vuetify from '@/plugins/vuetify';

const state = {
    themeMode: themeConfig,
    vuetifyPreset: themePreset
};

const getters = {
    getThemeMode: state => state.themeMode,
    getVuetifyPreset: state => state.vuetifyPreset,
    theme: (state, getters) => getters.isThemeDark ? "dark" : "light",
    isThemeDark: (state, getters) => !!(getters.getCompany?.dark ?? Vuetify.framework?.theme?.dark ?? true),
    getCurrentTheme: (state, getters) => {
        // console.log("Themes", getters.theme, getters.getVuetifyPreset);
        const themeName = getters.theme;
        return getters.getVuetifyPreset.theme.themes[themeName];
    },
    getCompany: (state, getters, rootState, rootGetters) => {
        return rootGetters.getUser?.company ?? {
            color_appbar: null,
            color_background: null,
            color_primary: null,
            dark: null,
            config: null
        }
    },
    getLogo: (state, getters) => {
        return getters.getCompany?.logo;
    /* if (!getters.getMyGroupRoot) return 'logo-default.png'
    return getters.getMyGroupRoot === 3 ? 'logo-iris-assistance.png' : 'logo-iris-global.svg'; */
    },
    getColorAppBar: (state, getters) => getters.getCompany.color_appbar || (getters.isThemeDark ? '#28344a' : state.getThemeMode.appBarColor),
    getColorBackground: (state, getters) => getters.getCompany.color_background || getters.getCurrentTheme.background,
    getColorText: (state, getters) => getters.getCompany.color_primary || getters.getCurrentTheme.color,
    getColorPrimary: (state, getters) => getters.getCompany.color_primary || getters.getCurrentTheme.color
};

const actions = {
    updateCompanyStyle({ getters, rootGetters }) {
        const themeName = getters.theme;
        const theme = getters.getVuetifyPreset.theme.themes[themeName];

        if (rootGetters.loggedInUser) {
        // const company = getters.getCompany;

            theme.dark = getters.getColorAppBar.slice(0, 7);
            theme.background = getters.getColorBackground.slice(0, 7);
            theme.primary = getters.getColorText.slice(0, 7);

            const root = document.querySelector(':root');
            root.style.setProperty('--v-primary-base', theme.primary);
            root.style.setProperty('--v-background-base', theme.background);
            root.style.setProperty('--v-app-bar-background', theme.background);
            // --v-default_text_color-base
            // --v-default_icons_color-base
            const config = getters.getCompany?.config;

            if (config?.length) {
                config.split(',').forEach(item => {
                    const values = item.split(":");

                    if (values?.length === 2) {
                        if (values[0].startsWith("--v-")) {
                            root.style.setProperty(values[0].trim(), values[1].trim());
                        }
                    }
                });
            }
            // --v-default_icons_color-base: #00b5e2
        }

        Vuetify.framework.theme.themes[themeName] = theme;
        Vuetify.framework.theme.dark = getters.isThemeDark;
        // console.log('updateCompanyStyle', theme)
    },
    changeThemeMode({ commit }) {
        commit("toggleThemeMode");
    },
    changeThemeLayout({ commit }, data) {
        commit("toggleThemeLayout", data);
    },
    changeThemeDarkMode({ commit, getters, dispatch }, data) {
        const isDark = getters.getCompany?.is_dark ?? data;
        commit("toggleThemeDarkMode", isDark);

        dispatch('updateCompanyStyle');
    },
    changeThemeRtl({ commit }) {
        commit("toggleThemeRtl");
    },
    changeVerticalSidebarDrawer({ commit }) {
        commit("toggleVerticalSidebarDrawer");
    },
    changeVerticalSaasSidebarDrawer({ commit }) {
        commit("toggleVerticalSaasSidebarDrawer");
    },
    changeVerticalSidebarDrawerColor({ commit }, data) {
        commit("toggleVerticalSidebarDrawerColor", data);
    },
    changeAppBarColor({ commit }, data) {
        commit("toggleAppBarColor", data);
    },
    changeCompactVerticalSidebarDrawer({ commit }) {
        commit("toggleCompactVerticalSidebarDrawer");
    },
    changeVerticalSidebarMini({ commit }) {
        commit("toggleVerticalSidebarMini");
    },

    changeThemeLoadingState({ commit }, payload) {
        commit("toggleThemeLoadingState", payload);
    }
};

const mutations = {
    toggleThemeDarkMode(state, data) {
        state.themeMode.isDark = data;
        if (Vuetify?.framework?.theme) { Vuetify.framework.theme.dark = data; }
    },
    toggleThemeLayout(state, data) {
        state.themeMode.layout = data;
    },
    toggleThemeRtl(state) {
        state.themeMode.rtl = !state.themeMode.rtl;
    },
    toggleThemeLoadingState(state, payload) {
        state.themeMode.isLoading = payload;
    },
    toggleVerticalSidebarDrawer(state) {
        state.themeMode.verticalSidebarDrawer = !state.themeMode
            .verticalSidebarDrawer;
    },
    toggleVerticalSaasSidebarDrawer(state) {
        state.themeMode.verticalSaasSidebarDrawer = !state.themeMode
            .verticalSaasSidebarDrawer;
    },
    toggleVerticalSidebarDrawerColor(state, data) {
        state.themeMode.verticalSidebarDrawerColor = data;
    },
    toggleAppBarColor(state, data) {
        state.themeMode.appBarColor = data;
    },
    toggleCompactVerticalSidebarDrawer(state) {
        state.themeMode.verticalCompactSidebarDrawer = !state.themeMode
            .verticalCompactSidebarDrawer;
    },
    toggleVerticalSidebarMini(state) {
        state.themeMode.verticalSidebarMini = !state.themeMode.verticalSidebarMini;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
