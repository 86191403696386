import axiosInstance from '../../plugins/axios'

import authData from "@/store/modules/authData";
import axios from "@/plugins/axios";

import i18n from '@/lang/lang'

import Vue from 'vue';
import date from "../../helpers/date";

export default {
    namespaced: true,
    state: {
        myInteractions: {},
        tags: {
            list: [],
            map: {}
        },
        types: []
    },
    getters: {
        getTags: state => state.tags,
        getMyInteractions: state => state.myInteractions,
        getTypes: state => state.types
        /* getPostsOrdered: (state, getters, rootState, rootGetters) => {
            const lang = rootGetters["languages/getLang"];

            return state.posts
                .sort((a, b) => parseInt(a.attributes.order) > parseInt(b.attributes.order) ? 1 : -1)
                .filter(post => {
                    if (post.attributes.lang === lang) {
                        if (post.attributes.url !== "wasNominated") return true;
                        return rootGetters["configGeneral/wasNominated"];
                    }
                });
        }, */
    },
    mutations: {
        setMyInteractions(state, values) {
            const items = {};
            values.forEach(item => {
                items[item.documents_list_id] = {
                    favorite: item.favorite,
                    vote_value: item.vote_value,
                    viewed: item.viewed,
                    later: item.later,
                    document: item.document
                }
            });

            state.myInteractions = items;
        },
        setFavorite(state, { key, value, document }) {
            if (!state.myInteractions[key]) {
                Vue.set(state.myInteractions, key, {
                    favorite: value,
                    viewed: undefined,
                    later: undefined,
                    vote_value: undefined,
                    document: document
                });
            } else {
                state.myInteractions[key].favorite = value;
            }
        },
        setVote(state, { key, value, document }) {
            if (!state.myInteractions[key]) {
                Vue.set(state.myInteractions, key, {
                    vote_value: value,
                    favorite: undefined,
                    viewed: undefined,
                    later: undefined,
                    document: document
                });
            } else {
                state.myInteractions[key].vote_value = value;
            }
        },
        setLater(state, { key, value, document }) {
            if (!state.myInteractions[key]) {
                Vue.set(state.myInteractions, key, {
                    later: value,
                    favorite: undefined,
                    vote_value: undefined,
                    viewed: undefined,
                    document: document
                });
            } else {
                state.myInteractions[key].later = value;
            }
        },
        setViewed(state, { key, value, document }) {
            if (!state.myInteractions[key]) {
                Vue.set(state.myInteractions, key, {
                    viewed: value,
                    later: undefined,
                    favorite: undefined,
                    vote_value: undefined,
                    document: document
                });
            } else {
                state.myInteractions[key].viewed = value;
            }
        },
        setTags(state, tags) {
            state.tags.list = tags;

            const map = {};
            tags.forEach(tag => { map[tag.id] = tag });
            state.tags.map = map;
        },
        setTypes(state, types) {
            state.types = types;
        }
    },
    actions: {
        async loadMyInteractions({
            commit,
            state,
            dispatch
        }) {
            try {
                if (Object.keys(state.myInteractions).length) {
                    return state.myInteractions;
                }

                const myInteractions = await axiosInstance
                    .get('documents/myInteractions')

                commit('setMyInteractions', myInteractions.data.data)

                return state.myInteractions
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        },
        async updateFavorite({ state, getters, commit, dispatch }, { key, value, document }) {
            const previousValue = state.myInteractions[key]?.favorite;

            commit('setFavorite', { key, value: value ? date.currentDateTimeServer() : null, document })
            // console.log('setFavorite', key, value);

            try {
                const isFavoriteValue = value ? 1 : 0;
                await axios
                    .post('documents/changeFavorite/' + key, { favorite: isFavoriteValue })
            // const values = result.data.data;
            } catch (e) {
                commit('setFavorite', previousValue);

                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        },
        async updateVote({ state, getters, commit, dispatch }, { key, value, document }) {
            const previousValue = state.myInteractions[key]?.vote_value;

            commit('setVote', { key, value: value, document })

            try {
                await axios
                    .post('documents/changeVote/' + key, { vote: value })
                // const values = result.data.data;
            } catch (e) {
                commit('setVote', previousValue);

                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        },
        async updateLater({ state, getters, commit, dispatch }, { key, value, document }) {
            const previousValue = state.myInteractions[key]?.later;

            commit('setLater', { key, value: value ? date.currentDateTimeServer() : null, document })
            // console.log('setFavorite', key, value);

            try {
                const isLaterValue = value ? 1 : 0;
                await axios
                    .post('documents/changeLater/' + key, { later: isLaterValue })
                // const values = result.data.data;
            } catch (e) {
                commit('setLater', previousValue);

                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        },
        async updateViewed({ state, getters, commit, dispatch }, { key, document }) {
            const previousValue = state.myInteractions[key]?.viewed;

            commit('setViewed', { key, value: date.currentDateTimeServer(), document })
            // console.log('setFavorite', key, value);

            try {
                await axios
                    .post('documents/eventViewed/' + key)
                    // const values = result.data.data;
            } catch (e) {
                commit('setViewed', previousValue);

                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        },
        async loadTags({
            commit,
            state,
            dispatch
        }, forceLoad = false) {
            try {
                if (!forceLoad && state.tags.list.length) {
                    return state.tags;
                }

                const tags = await axiosInstance
                    .get('documents/tags')

                commit('setTags', tags.data.data)

                return state.tags;
            } catch (error) {
                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        },
        async loadTypes({
            commit,
            state,
            dispatch
        }, forceLoad = false) {
            try {
                if (!forceLoad && state.types.length) {
                    return state.types;
                }

                const types = await axiosInstance
                    .get('documents/types')

                commit('setTypes', types.data)

                return state.tags;
            } catch (error) {
                dispatch('snackbar/error', i18n.t('common.error.loading'), { root: true });
            }
        }
    }
}
