const state = () => ({
    status: false,
    percent: 0,
    message: ''
})

const mutations = {
    SET(state, status) {
        state.status = status

        if (!status) {
            state.percent = 0;
            state.message = '';
        }
    },
    setMessage(state, message) {
        state.message = message
    },
    setPercent(state, percent) {
        state.percent = percent
    }
}

const actions = {
    start({ commit, dispatch }, payload) {
        const { message = '', percent = 0 } = payload ?? {};

        if (percent || message) dispatch('changePercent', { message, percent });

        commit('SET', true);
    },
    end({ commit }) {
        commit('SET', false)
    },
    changePercent({ commit }, { message = '', percent = 0 }) {
        if (percent) commit('setPercent', percent);
        if (message) commit('setMessage', message);
    }
}

const getters = {
    getStatus: (state) => {
        return state.status
    },
    getMessage: (state) => {
        return state.message
    },
    getPercent: (state) => {
        return state.percent
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
