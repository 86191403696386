import axiosInstance from './../../plugins/axios'
import axios from '@/plugins/axios'

export default {
    state: {
        loggedInUser:
      localStorage.getItem('userInfo') != null
          ? JSON.parse(localStorage.getItem('userInfo'))
          : null,
        loading: false,
        error: null
    },
    getters: {
        loggedInUser: state => state.loggedInUser, // token, user, roles
        getUser: state => state.loggedInUser?.user ?? {
            id: "",
            username: "",
            name: "",
            lastname: "",
            email: "",
            picture: "",
            email_verified_at: null,
            created_at: "",
            updated_at: "",
            index_show_post: 0, // this is the maximum index for show the posts
            company_id: null,
            company: {
                id: null,
                name: null,
                title: null,
                logo: null,
                color_appbar: null,
                color_background: null,
                color_primary: null,
                config: null,
                dark: null
            }
        },
        getUserPicture: (state, getters) => {
            const picture = getters.getUser.picture;
            return !picture ? ''
                : process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=users/images/' + picture;
        },
        getUserAcronym: (state, getters) => {
            let acronym = "";
            const username = getters.getUserName;
            if (username) {
                acronym = username.substring(0, 1).toUpperCase();
                acronym += username.substring(1).split("").find(e => e.toUpperCase() === e) ?? "";
            }
            return acronym;
        },
        getUserId: state => state.loggedInUser?.user?.id ?? '',
        getUserName: state => state.loggedInUser?.user?.username ?? '',
        getUserRoles: state => state.loggedInUser?.roles ?? [],
        isAdmin: (state, getters) => !!getters.getUserRoles.find(r => r.name === 'Admin'),
        getToken: state => state.loggedInUser?.token ?? '',
        loading: state => state.loading,
        error: state => state.error,
        getIndexShowPost: state => state.loggedInUser?.user?.index_show_post ?? 0,
        /* getMyGroups: state => state.loggedInUser?.myGroups ?? [],
        getMyGroupRoot: state => state.loggedInUser?.myGroupRoot ?? '', */
        getCompanyId: (state, getters) => getters.getUser?.company_id,
        getUserPermissions: state => state.loggedInUser?.permissions ?? []
    },
    mutations: {
        setUser(state, data) {
            state.loggedInUser = data
            state.loading = false
            state.error = null

            localStorage.setItem('userInfo', JSON.stringify(state.loggedInUser))
        },
        setUserSingle(state, user) {
            user.company_id = state.loggedInUser?.user?.company_id;
            user.company_id = state.loggedInUser?.user?.company;

            if (state.loggedInUser) {
                state.loggedInUser.user = user
            } else {
                state.loggedInUser = { user }
            }

            state.loading = false
            state.error = null

            localStorage.setItem('userInfo', JSON.stringify(state.loggedInUser))
        },
        setLogout(state) {
            state.loggedInUser = null
            state.loading = false
            state.error = null
            // this.$router.go("/");
        },
        setLoading(state, data) {
            state.loading = data
            state.error = null
        },
        setError(state, data) {
            state.error = data
            state.loggedInUser = null
            state.loading = false
        },
        clearError(state) {
            state.error = null
        }
    },
    actions: {
        async login({ commit, dispatch }, data) {
            commit('clearError')
            commit('setLoading', true)

            try {
                const login = await axiosInstance
                    .post('login', {
                        username: data.username,
                        password: data.password
                    })
                /* const token = login.data.token
                const username = login.data?.username
                const roles = login.data?.roles

                const user = {
                    token,
                    username,
                    roles
                }; */

                await dispatch('setUser', login.data);

                await dispatch('permissions/loadMyPermissions');

                await dispatch('updateCompanyStyle');

                commit('setLoading', false);

                return true;
            } catch (error) {
                commit('setLoading', false);
                if (error.response) {
                    return error.response.data;
                    /* console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers) */
                }
                return 'connection_issues';
            }
        },
        setUser({ commit }, { token, user, roles, permissions }) {
            const userData = {
                token,
                user,
                roles,
                permissions
            };
            commit('setUser', userData);
        },
        async setUserSimple({ getters, dispatch, commit }, { username, email, name, lastname, picture }) {
            const user = {
                username, email, name, lastname, picture, index_show_post: getters.getIndexShowPost
            };

            commit('setUserSingle', user);
        },
        async setIndexShowPost({ state, getters, commit }, indexShowPost) {
            if (getters.getIndexShowPost < indexShowPost) {
                const user = getters.getUser;
                user.index_show_post = indexShowPost;

                commit('setUserSingle', user);

                try {
                    axiosInstance
                        .post('updateIndexShowPost', {
                            index_show_post: indexShowPost
                        })
                } catch (e) {
                }
            }
        },
        signUserUp({ commit }, data) {
            commit('setLoading', true)
            commit('clearError')

            // data.email, data.password

            /** OK
       {
                    commit("setLoading", false);

                    const newUser = {
                        uid: user.user.uid
                    };
                    console.log(newUser);
                    localStorage.setItem("userInfo", JSON.stringify(newUser));
                    commit("setUser", newUser);
                }
       * */

            /** ERROR
       {
                    commit("setLoading", false);
                    commit("setError", error);
                    localStorage.removeItem("userInfo");
                    console.log(error);
                }
       * */
        },
        signOut({ commit, dispatch }) {
            localStorage.removeItem("userInfo");
            commit("setLogout");
            dispatch('loading/end');
            dispatch('snackbar/hideSnackbar')
        }/*,
        async fetchMyGroups({ commit, dispatch }) {
            try {
                const groups = await axios
                    .get('/myGroups')

                const data = groups.data;

                commit("setMyGroups");
            } catch (e) {
                dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        } */
    }
}
