<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex,}"
        @keydown.esc="cancel"
    >
        <v-card>
            <!--          :color="options.color"-->
            <v-card-title class="text-h5">
                {{ title }}
            </v-card-title>

            <v-card-text
                v-show="!!message"
                class="pa-4 "
                v-html="message"
            />

            <v-card-actions class="pt-3">
                <v-spacer />
                <v-btn
                    v-if="!options.noCancel"
                    color="primary"
                    text
                    class="text-body-2 font-weight-bold"
                    @click.native="cancel"
                >
                    {{ $t('common.confirmDialogCancel') }}
                </v-btn>
                <v-btn
                    v-if="!options.noOk"
                    color="primary"
                    class="text-body-2 font-weight-bold"
                    outlined
                    @click.native="agree"
                >
                    {{ $t('common.confirmDialogOk') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDlg",
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: "primary",
                width: 400,
                zIndex: 200,
                noCancel: false,
                noOk: false
            }
        };
    },

    methods: {
        open(title, message, options = null) {
            /* const width = this.$vuetify.breakpoint.width;
            this.options.width = width > 400 ? 400 : width - 10; */
            // console.log('width ', width)

            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
};
</script>
<style>
.v-dialog.v-dialog--active{
 border-radius: 8px;
}
</style>
